<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="dropdown">
        <router-link to="/dashboard" title="Link to Dashboard">
          <img src="/img/navbar-logo.png" class="mr-2" alt="logo" height="40"/>
        </router-link>
      </div>

      <button
        class="navbar-toggler text-white"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse d-lg-flex justify-content-end" id="navbarNavDropdown">
        <ul class="navbar-nav mr-auto ml-3" v-if="getCurrentUser">
          <li class="nav-item">
              <router-link to="/dashboard" class="nav-link text-white" title="Link to Dashboard">Dashboard</router-link>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Projects</a>
            <div class="dropdown-menu">
              <router-link to="/project/search" class="dropdown-item" href="#" title="Link to Project Search">Project Search</router-link>
              <router-link v-if="$permissions().hasAddProjectPermission || $permissions().hasAddPrivateProjectPermission" to="/project/new" class="dropdown-item" href="#" title="Link to Add Project">Add Project</router-link>
            </div>
          </li>

          <li class="nav-item dropdown" v-if="$permissions().hasViewCompanyPermission">
            <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Companies</a>
            <div class="dropdown-menu">
              <router-link to="/company/search" class="dropdown-item" href="#" title="Link to Company Search">Company Search</router-link>
              <router-link v-if="$permissions().hasAddCompanyPermission" to="/company/new" class="dropdown-item" href="#" title="Link to Add Company">Add Company</router-link>
            </div>
          </li>

          <li class="nav-item dropdown" v-if="$permissions().hasConfigurePermissions">
            <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Configure</a>
            <div class="dropdown-menu">
              <router-link v-if="$permissions().hasSendBidInvitations" to="/configuration/addressbook" class="dropdown-item" title="Link to Address Books">Address Books</router-link>
              <router-link v-if="$permissions().hasViewAdvertisementPermission" to="/configuration/advertisements" class="dropdown-item" title="Link to Advertisements">Advertisements</router-link>
              <router-link v-if="$permissions().isSysAdmin" to="/configuration/contact-types" class="dropdown-item" title="Link to Contact Types">Contact Types</router-link>
              <router-link v-if="isNewsAvailable" to="/configuration/news" class="dropdown-item" title="Link to News">News</router-link>
              <router-link v-if="$permissions().hasViewOwnerPermission" to="/configuration/owners" class="dropdown-item" title="Link to Owners">Owners</router-link>
              <router-link v-if="$permissions().isSysAdmin" to="/configuration/owner-types" class="dropdown-item" title="Link to Owner Types">Owner Types</router-link>
              <router-link v-if="$permissions().isSysAdmin" to="/configuration/participant-types" class="dropdown-item" title="Link to Participant Types">Participant Types</router-link>
              <router-link v-if="isPaymentReportsAvailable" to="/configuration/payments" class="dropdown-item" title="Link to Payment Reports">Payment Reports</router-link>
              <router-link v-if="$permissions().isSysAdmin" to="/configuration/procurement-types" class="dropdown-item" title="Link to Procurement Types">Procurement Types</router-link>
              <router-link v-if="$permissions().hasViewRolePermission" to="/configuration/roles" class="dropdown-item" title="Link to Roles">Roles</router-link>
              <router-link v-if="$permissions().hasViewServiceRegionPermission" to="/configuration/service-regions" class="dropdown-item" title="Link to Service Regions">Service Regions</router-link>
              <router-link v-if="$permissions().isSysAdmin" to="/configuration/tender-stages" class="dropdown-item" title="Link to Tender Stages">Tender Stages</router-link>
              <router-link v-if="$permissions().isSysAdmin" to="/configuration/trade-agreements" class="dropdown-item" title="Link to Trade Agreements">Trade Agreements</router-link>
              <router-link v-if="$permissions().hasViewUserPermission" to="/configuration/users" class="dropdown-item" title="Link to Users">Users</router-link>
              <router-link v-if="$permissions().hasViewZonePermission" to="/configuration/zones" class="dropdown-item" title="Link to Zones">Zones</router-link>
            </div>
          </li>

          <li class="nav-item" v-if="isBidCentralClient">
            <a :href="myPlanroomLink" class="nav-link text-white" target="_blank" title="Link to myplanroom">myPlanroom</a>
          </li>

          <li class="nav-item" v-if="isBidCentralClient">
            <a :href="permitsLink" class="nav-link text-white" target="_blank" title="Link to Permits">Permits</a>
          </li>

          <li class="nav-item" v-if="usePublicDirectoryLink">
            <a :href="publicDirectoryLink" class="nav-link text-white" target="_blank" title="Link to Public Directory">Directory</a>
          </li>
        </ul>

        <div class="nav-item dropdown" v-if="getCurrentUser">
          <a class="nav-link text-white dropdown-toggle p-0" href="#" id="navbarDropdownUser" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <app-avatar class="mr-2" :name="getUserName" :size="40"></app-avatar>
            <span>{{ getUserName }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownUser">
            <router-link to="/account/settings" class="dropdown-item" title="Link to Account">Account</router-link>
            <button v-if="isMembershipPurchaseAvailable" @click="toUpgradeMembership" class="dropdown-item" title="Link to Account">Upgrade Membership</button>
            <router-link to="/logout" class="dropdown-item" title="Link to Logout">Logout</router-link>
          </div>
        </div>

        <div class="nav-item" v-if="!getCurrentUser">
          <router-link to="/login" class="nav-link text-white" href="#" title="Click to go to Login Page">Login</router-link>
        </div>
      </div>
    </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Avatar from '@/components/app-avatar.vue';
import Toast from "@/utils/toast";

export default {
  name: 'app-navbar',
  components: { 'app-avatar': Avatar },
  data: function () {
    return {
      publicDirectoryLink: null,
      useMembershipPayment: false,
      useNews: false,
      usePaymentReports: false,
      usePublicDirectoryLink: false,
      myPlanroomLink: "https://apps.bidcentral.ca/my-planroom/",
      permitsLink: "https://apps.bidcentral.ca/permits/"
    }
  },
  computed: {
    getUserName: function () {
      return `${ this.getCurrentUser.firstName } ${ this.getCurrentUser.lastName }`
    },
    getCurrentRoute() {      
      return this.$router.name
    },
    hasCompanies () {
      return !!this.getCurrentUser.companies.length;
    },
    hasPaidMembership () {    
      var roles = []
      this.getUser.companies.forEach(company => 
        company.memberships.forEach(
            membership => {
            roles.push(membership.role.toLowerCase())
      }))
      return roles.some(c => c !== "public sign up membership")
    },
    isBidCentralClient () {
      return process.env.VUE_APP_TENANT_NAME.toLowerCase().includes('bidcentral')
    },
    isMembershipPurchaseAvailable () {
      if (!this.useMembershipPayment) {
        return false;
      }
      return !this.$permissions().isSysAdmin && !this.$permissions().isClientAdmin && this.hasCompanies;
    },
    isNewsAvailable () {
      if (!this.useNews) {
        return false;
      }
      return this.$permissions().isSysAdmin || this.$permissions().isClientAdmin;
    },
    isPaymentReportsAvailable () {
      if (!this.usePaymentReports) {
        return false;
      }
      return this.$permissions().hasViewPaymentPermission;
    },
    ...mapGetters(["getUser","getCurrentUser", "getUseDisplayNews", "getPublicDirectoryLink", "getUseMembershipPayment", "getUsePaymentReports"])
  },
  methods: {
    ...mapActions(["fetchClientSettings", "fetchUser"]),

    toUpgradeMembership(){
      if(this.$router.history.current.path !== "/membership/upgrade"){
        if(this.isMembershipPurchaseAvailable && this.hasPaidMembership){
        this.$router.push('/membership/upgrade')
        }else{
          var message = this.$createElement("span", {},
            [" If you currently have a free public membership please contact your local association to upgrade your membership. See list of associations ", 
              this.$createElement('a',{attrs: {href: 'https://www.link2build.ca/about/become-a-member/', target: '_blank'}}, ['here'])]
          );
          
          Toast.danger(this, [message], { autoHideDelay: 15000 });          
        }
      }            
    }
  },
  created () {
    this.fetchClientSettings().then(() => {
      this.usePublicDirectoryLink = !!this.getPublicDirectoryLink;
      this.publicDirectoryLink = this.getPublicDirectoryLink;
      this.useMembershipPayment = (this.getUseMembershipPayment || '').toUpperCase() === 'TRUE';
      this.useNews = (this.getUseDisplayNews || '').toUpperCase() === 'TRUE';
      this.usePaymentReports = (this.getUsePaymentReports || '').toUpperCase() === 'TRUE';
      this.fetchUser(this.getCurrentUser.id)
    });
  }
}
</script>
