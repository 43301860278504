<template>
  <div class="main_page">
    <app-navbar></app-navbar>
    <div class="page">
      <loading :active.sync="isPageLoading" is-full-page></loading>
      <div>
        <div class="page-title">
          <h1>Project Purchase</h1>
        </div>

        <div class="page-content" v-if="!isPageLoading">
          <div class="row d-flex justify-content-end">
            <menu class="d-flex align-items-center justify-content-end m-0">
              <router-link :to="{ name: 'project-info', query: $route.params.qs }" title="Back to Project">Back to Project</router-link>
            </menu>
          </div>

          <div class="alert alert-danger mt-2" role="alert" v-if="isError">
            <i class="fas fa-exclamation-triangle mr-2"></i>
            Unable to load purchase information for this project
          </div>

          <div class="block-heading">
            <h2>Payment</h2>
            <p>
              Upon purchase, you will gain access to further details of the project -
              <strong> please note: </strong> you will be taken outside of the site to provide payment information.
            </p>

            <div class="table-responsive">
              <table class="table table-borderless table-sm mt-3">
                <tbody>
                  <tr>
                    <td class="p-0">Project Title</td>
                    <td class="p-0">{{ getProject.title }}</td>
                  </tr>
                  <tr>
                    <td class="p-0">Project Number</td>
                    <td class="p-0">{{ getProject.ocaaNumber }}</td>
                  </tr>
                  <tr>
                    <td class="p-0">Zone</td>
                    <td class="p-0">{{ getZoneDisplay }}</td>
                  </tr>
                  <tr>
                    <td class="p-0">Closing Date</td>
                    <td class="p-0">{{ getProject.closingDate || 'N/A' | datetime('eeee, MMMM dd yyyy, h:mm aa') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <form v-on:submit.prevent="onSubmit">
              <div class="mt-2 mb-2" v-if="companies.length > 1">
                <div class="form-group">
                <label for="purchaserCompany">Company</label>
                <select id="purchaserCompany" name="purchaserCompany" class="custom-select custom-select-sm" :class="{ 'is-invalid': errors.has('purchaserCompany') }"
                    v-model="selectedCompany" v-validate="'required'">
                  <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                </select>
                <div class="invalid-feedback">Please select a company</div>
                <small id="emailHelp" class="form-text text-muted">
                  You are associated to multiple companies. Please select which company you are making this purchase for.
                </small>
                </div>
              </div>

              <div class="checkout">
                <h3 class="title">Checkout</h3>
                <div class="charge">Amount<span class="price">${{ amount.toFixed(2) }}</span></div>
                <div class="charge">Tax<span class="price">${{ tax.toFixed(2) }}</span></div>
                <div class="charge">Total<span class="price">${{ total.toFixed(2) }}</span></div>
              </div>

              <div class="mt-2 footer">
                <button type="submit" class="btn btn-primary ld-ext-right" :class="{ 'running' : isSaving }" :disabled="isError || isSaving">
                  <i class="fas fa-credit-card"></i>
                  Purchase
                  <div class="ld ld-ring ld-spin"></div>
                </button>
                <router-link :to="{ name: 'project-info', query: $route.params.qs }" class="btn btn-warning" title="Back to Project">Cancel</router-link>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.checkout {
  padding: 25px 0px;

  .title{
    font-size: 1em;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    font-weight: 600;
    padding-bottom: 8px;
  }

  .price{
    float: right;
    font-weight: 600;
    font-size: 0.9em;
  }

  .charge{
    padding-top: 19px;
    font-weight: 600;
    line-height: 1;
  }
}

.footer {
    -ms-flex-align: center;
    -ms-flex-pack: end;
    -webkit-box-align: center;
    align-items: center;
    border-top: 1px solid #dee2e6;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding: 1rem 0;
}

.footer > :not(:first-child) {
    margin-left: .25rem;
}

</style>

<script>
import JwtHelper from "@/utils/jwtHelper";
import { mapGetters, mapActions } from "vuex";
import AppNavBar from "@/components/app-navbar.vue";
import { PaymentService } from "@/services/";
import Toast from "@/utils/toast";

export default {
  components: {
    "app-navbar": AppNavBar
  },
  data: function() {
    return {
      isError: false,
      isPageLoading: true,
      isSaving: false,
      amount: 0,
      tax: 0,
      total: 0,
      companies: [],
      selectedCompany: null
    };
  },
  computed: {
    getZoneDisplay() {
      let zone = this.getZones.find(zone => zone.id == this.getProject.belongZoneId) || {};
      return zone.name || "";
    },
    ...mapGetters(["getProject", "getCurrentUser", "getZones"])
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.purchaseProject();
    },
    purchaseProject () {
      this.isSaving = true;

      let params = { projectId: this.$route.params.id, companyId: this.selectedCompany };
      PaymentService.post(params)
        .then(({ headers }) => {
          this.isSaving = false;
          let location = headers.location;

          if (location)
            window.location.href = location;
        })
        .catch(err => {
          this.isSaving = false;
          var message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);

          if (err && err.response && err.response.data && err.response.data.exceptionMessage) {
            message = this.$createElement('span', {}, [err.response.data.exceptionMessage, this.$createElement('br'), 'Please contact an administrator']);
          }

          Toast.danger(this, [message], { autoHideDelay: 7500 });
        });
    },
    ...mapActions([
      "fetchProject",
      "fetchUser",
      "fetchZones",
    ])
  },
  mounted() {
    Promise.all([
      this.fetchProject(this.$route.params.id),
      this.fetchUser(this.getCurrentUser.id),
      this.fetchZones(),
      PaymentService.getProviderDetailsByProject(this.$route.params.id)
    ])
    .then(result => {
      this.isPageLoading = false;
      this.companies = result[1].companies;
      this.companies.length === 1 && (this.selectedCompany = this.companies[0].id);
      this.amount = result[3].data.amount;
      this.tax = result[3].data.tax;
      this.total = result[3].data.total;
    })
    .catch(() => {
      this.isPageLoading = false;
      this.isError = true;
      let message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);
      Toast.danger(this, [message], { autoHideDelay: 7500 });
    });
  },
  beforeRouteEnter (to, from, next) {
    let user = JwtHelper.getJwtToken();

    if (user && user.companies && user.companies.length) {
      next();
    }
    else {
      next(from);
    }
  }
};
</script>
