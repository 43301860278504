<template>
  <svg class="flex-shrink-0" :style="svgStyle">
    <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central" :style="textStyle">
      {{ avatarLetters }}
    </text>
  </svg>
</template>

<script>
export default {
  name: 'app-avatar',
  props: {
    size: { required: true, type: Number, default: 40 },
    name: { required: true, type: String }
  },
  data: function () {
    return {
      backgroundColors: [
        '#011627', '#011627', '#41EAD4', '#FDFFFC ', "#0D3B66", "#FAF0CA", "#F95738", "#FDFFFC"
      ]
    }
  },
  computed: {
    avatarLetters () {
      return this.initial(this.name);
    },
    svgStyle () {
      let backgroundColor = this.backgroundColors[this.avatarLetters.charCodeAt(0) % this.backgroundColors.length];
      return { width: `${ this.size }px`, height: `${ this.size }px`, borderRadius: '50%', background: backgroundColor }
    },
    textStyle () {
      return { fill: 'rgba(0, 0, 0, .7)', fontSize: `${this.size * 0.4}px` }
    }
  },
  methods: {
    initial (username) {
      let parts = username.split(' ')
      let initials = ''
      for (var i = 0; i < parts.length; i++) {
        initials += parts[i].charAt(0)
      }
      return initials.toUpperCase();
    },
  }
}
</script>
